const InvestorRelations = {
  groupTitle: "Investor Relations",
  sidebar: [
    {
      id: "sidebar-investor-relations",
      title: "Investor Relations",
      url: "/about-us/investor-relations"
    },
    {
      id: "sidebar-financial-news",
      url: "/about-us/investor-relations/financial-news",
      title: "Financial News"
    },
    {
      id: "sidebar-company-history",
      url: "/about-us/investor-relations/company-history",
      title: "Company History"
    },
    {
      id: "sidebar-corporate-governance",
      url: "/about-us/investor-relations/corporate-governance",
      title: "Corporate Governance"
    },
    {
      id: "sidebar-corporate-and-social-responsibility",
      url: "/about-us/investor-relations/csr",
      title: "Corporate and Social Responsibility"
    },
    {
      id: "sidebar-wfsl-financial-data",
      url: "/about-us/investor-relations/wfsl-financial-data",
      title: "Other Financial Data"
    },
    {
      id: "sidebar-stock-dividend-history",
      url: "/about-us/investor-relations/stock-dividend-history",
      title: "Dividend History"
    },
    {
      id: "sidebar-leadership-board",
      url: "/about-us/investor-relations/leadership-board",
      title: "Leadership Team"
    }
  ]
};

export default InvestorRelations;
